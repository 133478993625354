import React, { useEffect } from "react";
import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/login/index";
import Students from "./components/students";
import FaqStudents from "./components/faq/faq-students";
import PrivateRoute from "./components/route/private-route";
import SendEmailResetPassword from "./components/reset-password/send-email";
import ModifyPassword from "./components/reset-password/modify-password";
import { useSelector, useDispatch } from "react-redux";
import { saveState } from "./redux/store";
import ReduxToastr, { toastr } from "react-redux-toastr";
import { createBrowserHistory } from "history";
import { BASE_URL_SITE_TEACHER, ROLE_TEACHER_STR, ROLE_DIRECTOR_STR, IS_APP_MAINTENANCE } from "./config/environment";
import { hasRole, getUser } from "./utils/utils";
import Maintenance from "./components/common/maintenance/maintenance";
import { getSettings } from "./redux/actions/user";

export const history = createBrowserHistory();

const App = () => {
  const { error } = useSelector((state) => ({
    error: state.error,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("unload", saveState);
    window.addEventListener("message", (e) => {
      if (e.origin !== BASE_URL_SITE_TEACHER) {
        return;
      }
      const data = e.data;
      const { type, body } = data;
      if (type === 'login' && body) {
        localStorage.setItem("auth", JSON.stringify(body));
      } else if (type === 'logout') {
        if (hasRole(getUser(), [ROLE_TEACHER_STR, ROLE_DIRECTOR_STR])) {
          localStorage.removeItem("auth");
        }
      }
    });
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  return (
    <div>
      <Router history={history}>
        {!IS_APP_MAINTENANCE ? <Switch>
          <PrivateRoute path="/students" component={Students} />
          <Route exact path="/" component={Login} />
          <Route
            exact
            path="/password-reset"
            component={SendEmailResetPassword}
          />
          <Route exact path="/account-recovery" component={ModifyPassword} />
          <PrivateRoute exact path="/faq" component={FaqStudents} />
          {/* <Redirect from="/" to="login" /> */}
        </Switch> : <Maintenance />}
      </Router>
      <ReduxToastr />
    </div>
  );
};

export default App;
