import React, { useState, useEffect } from "react";
import "./style.css";
import GenialSkillsLogo from "../../../assets/images/Logo-SJ-estudia-avanza.svg";
import GSApp from "../../../assets/images/logo-SJ-APP.png";
import { useTranslation } from "react-i18next";
import { isMST } from "../../../utils/utils";
import { ENVIROMENT, BASE_URL_SITE_TEACHER, IS_APP_MANAGED_BY_REQUIRED } from "../../../config/environment";
import { Link } from "react-router-dom";

const languages = [
  { id: "es", name: "Español" },
  { id: "en", name: "English" },
];

const HeaderLogin = () => {
  const { t, i18n } = useTranslation(["login"]);
  const language = i18n.language.split("-")[0];
  const [languageSelected, setLanguageSelected] = useState(language);

  useEffect(() => {
    i18n.changeLanguage(language);
    setLanguageSelected(language);
  }, []);

  function onChangeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    setLanguageSelected(e.target.value);
  }

  return (
    <>
      <header className="main-header--dashboard">
        <div className="main-header_GenialSkills-logo--dashboard">
          <img src={GenialSkillsLogo} alt="" />
          {
            ENVIROMENT == "development" &&
            <span className="noticesDev">
              Dev
            </span>
          }
          {
            ENVIROMENT == "beta" &&
            <span className="noticesBeta">
              Beta
            </span>
          }
        </div>

        <div className="main-header_top-bar-menu--dashboard">
          <nav className="top-bar-menu--dashboard">
            {IS_APP_MANAGED_BY_REQUIRED && <li>
              <a
                href={`${BASE_URL_SITE_TEACHER}/select-plan`}
                target="_blank"
              >
                {" "}
                {t("subscribe")}{" "}
              </a>
            </li>}
            <li>
              <a
                href={IS_APP_MANAGED_BY_REQUIRED ? `${BASE_URL_SITE_TEACHER}/teachers` : `${BASE_URL_SITE_TEACHER}/`}
                target="_blank"
              >
                {" "}
                {t("teacher")}{" "}
              </a>
            </li>
            <li>
              <div className="top-bar-menu--dashboard_select">
                <i className="fas fa-globe"></i>
                <select onChange={onChangeLanguage} value={languageSelected}>
                  {languages.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
                </select>
              </div>
            </li>

            <li className="header__icon-APP">
              {!isMST() && (
                <a
                  href={`${BASE_URL_SITE_TEACHER}/download`}
                  target="_blank"
                >
                  <img src={GSApp} alt="" />
                  <span>
                    <strong> APP</strong>
                  </span>
                </a>
              )}
            </li>
          </nav>
        </div>
      </header>
    </>
  );
};

export default HeaderLogin;
